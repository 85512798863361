/*******************************
         Site Overrides
*******************************/

.ui.table thead th {
    border-bottom: none;
}

.ui.table tr.active,
.ui.table td.active {
    background: #cfffe0 !important;
}

.ui.table.edit-stock-table .field > label,
.ui.table.stock-list-table td > label {
    display: none;
}

.ui.table.stock-list-table td .ui.list {
    font-size: 0.8em;
}

.ui.table.edit-stock-table tbody tr > td {
    padding-top: 10px;
}

.ui.table.order-form-table tbody tr > td .mobile-order-label {
    display: none;
}

.ui.table.order-form-table tbody tr > td .total-amount-label {
    display: none;
}

.ui.table.order-form-table tbody tr.order-item-row {
    display: flex !important;
    flex-direction: column;
    padding-bottom: 0;
}

.ui.table.order-form-table tbody tr.order-item-row:nth-child(2n) {
    background-color: #FFFFFF;
}

.ui.table.order-form-table tbody tr > td.order-check-cell-mobile {
    background-color: #FAFAFA !important;
    border-top: none !important;
    order: 1;

    .mobile-current-stock {
        display: none;
    }  
}

@media (min-width: 599px){
    .ui.table.order-form-table tbody tr.order-item-row {
        display: block !important;
        padding-bottom: 1em;
    }

    .ui.table.order-form-table tbody tr > td.order-check-cell-mobile {
        display: none !important;
    }

    .ui.table.order-form-table tbody tr.order-item-row:nth-child(2n) {
        background-color: rgba(0, 0, 50, 0.02);
    }

    .ui.table.order-form-table .order-item-name {
        padding-left: 10px !important;
    }
}

@media (min-width: 669px){
    .ui.table.order-form-table tbody tr.order-item-row {
        display: table-row !important;
    }

    .ui.table.order-form-table tbody tr > td.order-check-cell.desktop {
        vertical-align: middle !important;
        background: #F2F2F2 !important;
    }
}

.ui.table.order-form-table tbody tr > td:nth-child(3n) {
    display: none !important;
}

.ui.table.order-form-table tbody tr > td.col-span-3 {
    display: block !important;
}

@media (min-width: 600px) and (max-width: 1023px) {

    .ui.table.edit-stock-table thead {
        display: none !important;
    }

    .ui.table.edit-stock-table .field > label {
        display: block;
        color: #9F9F9F;
    }

    .ui.table.edit-stock-table tbody tr {
        display: block;
        padding: 15px 10px !important;
    }
    .ui.table.edit-stock-table tbody > tr:not(:first-child) {
        border-top: 1px solid #eee;
    }
    .ui.table.edit-stock-table tbody tr > td {
        border: 0 !important;
    }
    .ui.table.edit-stock-table tbody tr > td:not(:first-child) {
        display: inline-block !important;
    }
    .ui.table.edit-stock-table tbody tr > td:last-child {
        display: block !important;
        width: 100% !important;
    }
    .ui.table.edit-stock-table tbody tr > td:first-child {
        width: 5% !important;
    }
    .ui.table.edit-stock-table tbody tr > td:nth-child(2),
    .ui.table.edit-stock-table tbody tr > td:nth-child(4) {
        width: 45% !important;
    }
    .ui.table.edit-stock-table tbody tr > td:nth-child(3) {
        width: 10% !important;
    }
    .ui.table.edit-stock-table tbody tr > td:last-child {
        width: 100% !important;
    }

    .ui.table.edit-stock-table tbody tr > td:nth-child(3) span {
        display: block;
        padding: 10px 0;
    }
}

@media (max-width: 729px) {
    .ui.table.stock-list-table thead tr,
    .ui.table.stock-list-table tbody tr {
        display: block !important;
    }
    .ui.table.stock-list-table thead tr > th span {
        display: none !important;
    }
    .ui.table.stock-list-table thead tr > th {
        display: inline-block !important;
    }
    .ui.table.stock-list-table thead tr > th:first-child span,
    .ui.table.stock-list-table thead tr > th:nth-child(3) span {
        display: inline-block !important;
    }
    .ui.table.stock-list-table thead tr > th:first-child > div:before {
        content: 'Sort By:';
        color: #ffffff;
        font-weight: normal;
        display: inline-block;
        margin-right: 20px;
    }

    .ui.table.stock-list-table tbody tr {
        border-top: 1px solid #eee;
        padding: 10px 0;
    }

    .ui.table.stock-list-table tbody tr > td {
        border: 0 !important;
    }

    .ui.table.stock-list-table td > label {
        display: block;
        color: #9F9F9F;
    }
    .ui.table.stock-list-table tbody tr > td {
        box-sizing: border-box;
    }

    .ui.table.stock-list-table tbody tr > td:nth-child(3),
    .ui.table.stock-list-table tbody tr > td:nth-child(4),
    .ui.table.stock-list-table tbody tr > td:nth-child(5) {
        display: inline-block !important;
        width: 33.3% !important;
    }

    .ui.table.stock-list-table tbody tr > td:last-child,
    .ui.table.stock-list-table tbody tr > td:nth-child(2) {
        display: block !important;
        text-align: center;
        padding-bottom: 0;
    }
    .ui.table.stock-list-table tbody tr > td:last-child span {
        font-size: large;
        font-weight: normal;
    }

    .ui.table.stock-list-table tbody tr > td:nth-child(2) > label {
        display: inline-block;
        margin-right: 10px;
    }

    .ui.table.stock-list-table tbody tr > td:nth-child(4) {
        text-align: center;
    }
    .ui.table.stock-list-table tbody tr > td:nth-child(5) {
        text-align: right;
    }
    .ui.table.stock-list-table tbody tr > td:last-child {
        display: block !important;
        width: 100% !important;
        text-align: center !important;
        padding-top: 10px !important;
    }
    .ui.table.stock-list-table tbody tr > td:last-child button {
        float: right;
    }
    .ui.table.stock-list-table tbody tr > td:last-child button:after {
        clear: both;
    }
}

@media (max-width: 599px) {
    .ui.table.edit-stock-table thead {
        display: none !important;
    }
    .ui.table.edit-stock-table tbody tr {
        padding: 1em 5px;
    }
    .ui.table.edit-stock-table .field > label {
        display: block;
        color: #9F9F9F;
    }
    .ui.table.edit-stock-table tbody tr > td:nth-child(2),
    .ui.table.edit-stock-table tbody tr > td:nth-child(3),
    .ui.table.edit-stock-table tbody tr > td:nth-child(5),
    .ui.table.edit-stock-table tbody tr > td:nth-child(6) {
        display: inline-block !important;
    }
    .ui.table.edit-stock-table tbody tr > td:nth-child(2) {
        width: 70% !important;
    }
    .ui.table.edit-stock-table tbody tr > td:nth-child(3) {
        width: 30% !important;
    }
    .ui.table.edit-stock-table tbody tr > td:last-child {
        width: 100% !important;
    }
}

@media (min-width: 599px) {
    .ui.table.order-form-table tbody tr > td:nth-child(3n) {
        display: inline-block !important;
        width: 8% !important;
    }

    .ui.table.order-form-table tbody tr > td.col-span-3 {
        width: 60% !important;
    }
}

@media (min-width: 669px) {
    .ui.table.order-form-table tbody tr > td:nth-child(3n) {
        width: 100% !important;
        padding-top: 1.8em;
        padding-bottom: 2.1em;
    }
    .ui.table.order-form-table tbody tr > td.col-span-3 {
        display: table-cell !important;
        padding: 1em 2em 1em 0;
        text-align: right;
    }
    .ui.table.order-form-table tbody tr > td.order-check-cell-mobile {
        background-color: #F2F2F2 !important;
    }
}

@media (min-width: 768px) {
    .ui.table.order-form-table .order-item-name {
        min-width: 210px !important;
    }
}

@media (max-width: 669px) {
    .ui.table.order-form-table thead {
       display: block !important;
    }

    .ui.table.order-form-table thead tr > th {
        display: inline-block !important;
    }

    .ui.table.order-form-table thead tr > th:first-child {
        width: 22% !important;
    }
    .ui.table.order-form-table thead tr > th:nth-child(2n) {
        width: 18% !important;
    }
    .ui.table.order-form-table thead tr > th:nth-child(3n) {
        width: 34% !important;
    }

    .ui.table.order-form-table thead tr > th:nth-child(4n) {
        width: 14% !important;
    }

    .ui.table.order-form-table thead tr > th:last-child {
        width: 10% !important;
        padding-right: 0 !important;
    }

    .ui.table.order-form-table tbody tr > td {
        display: inline-block !important;
    }

    .ui.table.edit-stock-table tbody tr > td:first-child {
        width: 100% !important;
    }
    .ui.table.order-form-table tbody tr > td:last-child {
        width: 15% !important;
    }
    .ui.table.order-form-table tbody tr > td.col-span-3:last-child {
        width: 60% !important;
        text-align: right;
    }
    .ui.table.order-form-table tbody tr > td:nth-child(2n) {
        width: 26% !important;
        padding: 0 0 0 10px !important;
    }
    
    .ui.table.order-form-table tbody tr > td:nth-child(4n) {
        width: 42% !important;
        padding: 15px 2% 0 2% !important;
    }

    .ui.table.order-form-table tbody tr > td.order-check-cell.desktop {
        width: 14% !important;
    }

    .ui.table.order-form-table tbody tr > td:nth-child(5n) {
        width: 9% !important;
    }
}

@media (max-width: 598px) {
    .ui.table.order-form-table tbody tr > td:nth-child(5n) {
        width: 100% !important;
        text-align: center;
        padding: 0 15px 0 5px !important;
        margin-bottom: 20px;
    }

    .ui.table.order-form-table thead {
       display: none !important;
    }
    
    .ui.table.order-form-table tbody tr > td {
        width: 90% !important;
        display: block !important;
        text-align: center;
        margin: 0 auto;
    }

    .ui.table.order-form-table tbody tr > td:nth-child(4n) {
        padding: 0.25em 0.75em !important;
        width: 100% !important;
    }

    .ui.table.order-form-table tbody tr > td:nth-child(2n) {
        width: 70% !important;
        padding: 8px 0 0 !important;
    }
    .ui.table.order-form-table tbody tr > td.order-check-cell.desktop {
        display: none !important;
    }
    .ui.table.order-form-table tbody tr > td:nth-child(3n) {
        padding: 20px 0 !important;
    }

    .ui.table.order-form-table tbody tr > td .mobile-order-label {
        display: inline;
        margin-right: 5px;
    }

    .ui.table.order-form-table tbody tr > td .total-amount-label {
        display: inline;
    }

    .ui.table.order-form-table tbody tr > td p {
        display: block !important;
    }

    .ui.table.order-form-table tbody tr > td.order-check-cell-mobile p.large {
        display: inline !important;
    }

    .ui.table.order-form-table tbody tr > td p.large {
        margin-bottom: 2px;
    }

    .ui.table.order-form-table tbody tr > td p.small {
        font-size: 16px;
    }

    .ui.table.order-form-table tbody tr > td input {
        text-align: center !important;
    }

    .ui.table.order-form-table tbody tr > td.order-amount-cell-mobile {
         margin-top: 15px;
         margin-bottom: 15px;
    }

    .ui.table.order-form-table tbody tr > td.order-check-cell-mobile {
        display: flex !important;
        margin-top: 15px;
        justify-content: space-between;
        align-items: baseline;
        padding: 14px 10px 10px !important;
        border-bottom: 1px solid #BDBDBD !important;
        width: 100% !important;

        .mobile-current-stock {
            display: inline;
        }

        .mobile-checkbox-container {
            display: flex;
        }
    }

    .ui.table.order-form-table tbody tr > td.col-span-3:last-child {
        text-align: center;
        padding: 1em 0 1em !important;
    }
}

@media (min-width: 480px) {
    tr[data-id="mobile-stock-item"] {
        display: none !important;
    }
}

@media (max-width: 480px) {
    .ui.table thead[class=stock-table-headers] {

        th:nth-child(2), th:nth-child(3) {
            display: none !important;
        }

        th.desktop-header {
            display: none !important;
        }

        th.mobile-header {
            width: 45% !important;
        }
    }

    .ui.table tbody {

        tr[data-id="desktop-stock-item"] {
            display: none !important;
        }

        tr[data-id="mobile-stock-item"] {
            display: grid !important;
            padding-top: 0;
            padding-bottom: 0;

            td {
                width: 100% !important;
            }

            td:nth-child(1) {
                grid-column-start: 1;
                grid-column-end: 2;
                background-color: #FFFFFF !important;
                padding-top: 17px !important;
                padding-bottom: 17px !important;
            }

            td:nth-child(2) {
                grid-column-start: 2;
                grid-column-end: 3;
                display: flex !important;
                justify-content: space-around;
                background-color: #FFFFFF;
                padding-top: 17px !important;
                padding-bottom: 17px !important;

                p {
                    margin: 0px;
                }
            }

            td:nth-child(3) {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row: 2;
                background-color: #FAFAFA;
                padding-top: 8px !important;
                padding-bottom: 8px !important;
            }

            td:nth-child(4) {
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row: 2;
                background-color: #FAFAFA;
                padding-top: 8px !important;
                padding-bottom: 8px !important;
            }
        }
    }
}

