/*******************************
         Site Overrides
*******************************/
.ui.table.stock-list-table .ui.basic.button:focus {
    background: #F9FAFB !important;
    box-shadow: 0px 0px 3px 0px #4c4c4c inset;
}

.btn-container.bottom.fixed .ui.basic.button:hover,
.btn-container.bottom.fixed .ui.basic.button:focus,
.btn-container.bottom.fixed .ui.basic.button:active {
    background: @white !important;
}

.btn-container.bottom.fixed {
    position: absolute;
    bottom: 0px;
}

.ui.green.buttons .button,
.ui.green.button {
    background-color: @green !important;
}

.ui.green-custom.buttons .button,
.ui.green-custom.button {
    box-shadow: 0px 0px 0px 2px #7ED99F inset !important;
    background-color: #7ED99F !important;
    color: @white !important;
}

.ui.basic.green.buttons .button, .ui.basic.green.button {
    box-shadow: 0px 0px 0px 2px @green inset !important;
    background-color: @white !important;
    color: @green !important;
}

.ui.basic.orange-custom.buttons .button, .ui.basic.orange-custom.button {
    box-shadow: 0px 0px 0px 2px #EE8166 inset !important;
    background-color: @white !important;
    color: @green !important;
}

.ui.basic.green.button:disabled {
    background-color: @white !important;
    box-shadow: 0px 0px 0px 2px @lightGray inset !important;
    color: @lightGray !important;
}

.ui.basic.orange.buttons .button,
.ui.basic.orange.button {
    color: @darkGreen !important;
}

.ui.button:not(.stock-trigger-button) {
    width: 100%;
    max-width: 210px;
    min-height: 41px;
    border-radius: 25px !important;
    padding: 8px 16px !important;
    margin: 0px;
}

.ui.button.reduce-button,
.ui.button.increase-button {
    min-height:initial;
}

.ui.button:disabled {
    opacity: 0.6 !important;
}

.ui.button.secondary {
    background-color: @headerGreen
}

.ui.button.green:disabled {
    background-color: @lightGray !important;
}

.ui.button.red, .ui.button.red:focus {
    border: 1px solid #FF3B30;
    color: @green !important;
}