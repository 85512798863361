/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown {
  width: 100% !important;
  brder-radius: 20px;
}

.ui.selection.dropdown .menu {
  border-bottom-left-radius: @border-radius;
  border-bottom-right-radius: @border-radius;
}

.ui.multiple.dropdown > input {
  height: 19px !important;
}

.ui.selection.dropdown {
  border-top-left-radius: @border-radius !important;
  border-top-right-radius: @border-radius !important;
}

.ui.selection.dropdown[aria-expanded=false] {
  border-bottom-left-radius: @border-radius !important;
  border-bottom-right-radius: @border-radius !important;
}