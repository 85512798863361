/*******************************
         Site Overrides
*******************************/

.ui.modal {
  padding: 0px 16px;
  max-width: 510px;
}

.ui.mini.modal {
  height: 270px;
}

.ui.auto.modal {
  height: auto;
}

.ui.modal .content {
  max-height: 75% !important;
  height: 100%;
  padding: 16px 0px !important;
}

.ui.modal > .header {
    text-align: center;
    border-bottom: 1px solid @gray;
    background-color: @white;
    color: @gray;
    text-align: left;
    padding: 10px 0px !important;
    margin-top: 14px;
}

.ui.modal > .actions {
  display: flex;
  justify-content: space-between;
}
