/*******************************
         Site Overrides
*******************************/
.ui.menu .item {
    justify-content: center;
}

.ui.menu .item:before {
    display: none;
}

.ui.menu .item:last-child {
    border-radius: 0px 25px 25px 0;
}