/*******************************
         Site Overrides
*******************************/
.ui.checkbox {
    min-width: 24px;
    min-height: 24px;
}


.edit-stock-table .ui.checkbox label:before {
    width: 24px;
    height: 24px;
}

.edit-stock-table .ui.checkbox label:after {
    font-size: 24px;
    top: 3px;
}

.ui.checkbox label:before{
    border-radius: 100%;
}

.ui.toggle.checkbox label:before,
.ui.toggle.checkbox label:after {
    height: 1.8rem;
}

.ui.toggle.checkbox label:after {
    width: 1.8rem;
}

.ui.checkbox:not(.toggle) label:before,
ui.checkbox:not(.toggle) label:after,
.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  width: 24px;
  height: 24px;
}
.ui.checkbox:not(.toggle) label:after {
  font-size: 24px;
}
.ui.checkbox input.hidden + label {
    font-size: 16px;
    line-height: 24px;
    color: @darkGray;
}