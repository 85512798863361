/*******************************
    User Variable Overrides
*******************************/

.ui.menu {
  margin-bottom: 20px !important;
}

/* Menu container */
@background: #FFFFFF;
@border: 2px solid #63C1A1;
@borderRadius: 25px;
@boxShadow: none;

/* Menu Items */
@activeItemBackground: #63C1A1;
@activeItemTextColor: #FFFFFF;
@activeHoverItemColor: #FFFFFF;
@activePressedItemColor: #FFFFFF;
@activeHoverItemBackground: #26A658;
@itemTextColor: #000;
@hoverItemTextColor: #000;
@pressedItemTextColor: #FFFFFF;
