/*******************************
         Site Overrides
*******************************/

.ui.form {
    height: 100%;
}

.ui.form .success.message, .ui.form .warning.message, .ui.form .error.message {
    display: block;
}

.ui.form .field {
    clear: both;
    margin: 0em 0em 16px;
}

.ui.form input:not(.time-input, .controls-input, .search) {
    width: 100% !important;
    border: 1px solid @lightGray !important;
    padding: 10px 34px 10px 14px !important;
}

.ui.form input {
    height: 39px !important;
    border-radius: 80px !important;
}

.ui.form .error.field input {
    border-color: @lightRed !important;
}

.ui.form .left-icon-input input {
    padding: 10px 10px 10px 36px !important;
}