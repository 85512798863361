/*******************************
         Site Overrides
*******************************/
.amount-controls .ui[class*="left action"].input > input {
    border-radius: 0px !important;
    padding: @inputPadding 0.5em @inputPadding 0.5em !important;
}

.amount-controls .ui[class*="left action"].input > .button:last-child {
    border-radius: 0px @borderRadius @borderRadius 0px;
}


